.header_doc-filter-dropdown select{
    border: 0;
    outline: 0;
    width: 150px;
    margin-top: 5px;
    margin-left: 2px;
    font-family: Lato;
    font-style: normal;
    font-weight:normal;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.01em;
    color: #1953E7;
    padding: 5px;
}
.header_doc-filter-dropdown{
   margin-bottom: 8px;
   padding-left: 8px;
    align-items: center;
    padding-right: 10px;
    width: 178px;
    border: 1px solid #EDEAEA;
    border-radius: 5px;
    box-sizing: border-box;
    font-weight: 400;
    color: #1953E7 ;
    text-align: center;
    background-color: white;
    display: flex;

}