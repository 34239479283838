.leftDivAnnouncmetMsg {
    width: 73%;
    height: 70vh;
    background: #FFFFFF;
    box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-right: 24px;
    padding-left: 24px;
    padding-bottom: 24px;
    overflow: auto;
}

.leftDivAnnouncmetMsg::-webkit-scrollbar {
    border-radius: 8px;
    background: rgba(196, 196, 196, 0.4);
    box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
    width: 8px;
}

.leftDivAnnouncmetMsg::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, rgba(0, 151, 249, 0.4) -5.17%, rgba(0, 106, 175, 0.4) 106.03%);
    border-radius: 8px;
}

.msg_days_label_announcmentmsg {
    width: 38%;
    height: 22px;
    background: #2B64F1;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
    margin-top: 14px;
}

.msg_days_label_days {
    margin-left: 11px;
    margin-right: 12px;
}

.rightDivAnnouncmentMsg {
    background: #FFFFFF;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding-left: 27px;
    padding-right: 27px;
    padding-top: 31px;
    width: 18%;
    height: 70vh;
}

.announcmentTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #334D6E;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.announcmentDesc {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #8B8B8B;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 48px;
}

.announcmentDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
}

.ImageDiv {
    margin-top: 12px;
    display: flex;
    justify-content: space-around;
    background: #FFFFFF;
    border: 0.5px solid #1954E7;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    max-width: 25%;
    width: fit-content;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    align-items: center;
}

.ImageDiv p {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    margin-left: 8px;
    color: #2B64F1;
    width: 90%;
}

.msgtitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #454545;
}

.msgMessage {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8B8B8B;
}

.msgByAdmin {
    font-weight: 400;
    font-size: 11px;
    color: #454545;
}

.stateLine {
    background: #F0F0F0;
    height: 1px;
    width: 100%;
    margin-top: 8px;
}

.batchName {
    background: linear-gradient(0deg, #6BBFD8, #6BBFD8), #E2E9FF;
    border-radius: 24px;
    margin-right: 12px;
    margin-bottom: 8px;
    display: inline-block;
}

.msgHeader {
    display: flex;
    background: #FFFFFF;
    margin-top: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.08);
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 24px;
}

.msgHeader p {
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #334D6E;
    margin-right: 24px;
}