.clockmain {
  height: 100px;
  width: 100px;
  background-color: #fafafa;
  border-radius: 50%;
  border: 34px solid #24c5e5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leftDiv {
  width: 73%;
  display: flex;
  flex-direction: column;
}

.contentBox {
  background: #ffffff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.mb-10 {
  margin-bottom: 10px;
}

.bannerBox {
  height: 23vh;
  max-width: 97%;
  width: 100%;
  margin: 10px;
  display: block;
}

.bannerImgContainer {
  max-height: 23vh;
  flex-direction: row;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
}
.close-circle {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #FFF;
  position: absolute;
  top: 3%;
  right: 3%;
  cursor: pointer;
}
.slick-next {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #ffffff !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  right: -17px !important;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
}
.slick-next:before {
  content: url("../../../assets/dashboard/right_arrow.png") !important;
  transform: scale(0.4);
}
.slick-prev {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #ffffff !important;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  left: -17px !important;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  z-index: 1;
}
.slick-prev:before {
  content: url("../../../assets/dashboard/left_arrow.png") !important;
  transform: scale(0.4);
    max-height: 23vh;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    cursor: pointer;
    object-fit: contain;
}

.close-promo {
  position: "absolute";
  top: 3%;
  right: 3%;
  cursor: pointer;
}

.clockBox {
  height: 44vh;
}

.mainDiv {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.timeDiv {
  margin-top: 12px;
}

.timeDiv h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 54px;
  line-height: 63px;
  color: #000000;
}

.announsmenttop {
  height: 120px;
  background: linear-gradient(180deg, #228fe5 0%, #34d8e5 100%);
  backdrop-filter: blur(2px);
  border-radius: 20px;
  display: flex;
  padding-left: 24px;
  padding-top: 8px;
  justify-content: space-around;
}

.announsmenttitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 18px;
}

.announsmentdiscription {
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #ffffff;
  width: 120px;
}

.clock-student-homepage-announcment {
  border-radius: 100%;
  background: #f5f6fb;
  box-shadow: -6px -6px 16px #f8f8f8, 6px 6px 16px rgba(0, 0, 0, 0.12), inset -6px -6px 11px #ffffff,
    inset 6px 6px 16px 1px rgba(0, 0, 0, 0.06);
  width: 90px;
  /* margin-top: 30px; */
  height: 90px;
}

.wrap-student-homepage-announcment {
  overflow: hidden;
  position: relative;
  width: 90px;
  height: 90px;
  border-radius: 100%;
}

.minute-student-homepage-announcment,
.hour-student-homepage-announcment {
  position: absolute;
  height: 38px;
  width: 4px;
  margin: auto;
  top: -44%;
  left: 0;
  bottom: 0;
  right: 0;
  background: black;
  transform-origin: bottom center;
  transform: rotate(0deg);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
}

.minute-student-homepage-announcment {
  position: absolute;
  height: 45px;
  width: 3px;
  top: -50.5%;
  left: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  transform: rotate(90deg);
}

.second-student-homepage-announcment {
  position: absolute;
  height: 48px;
  width: 2px;
  margin: auto;
  top: -56%;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 4px;
  background: #ff4b3e;
  transform-origin: bottom center;
  transform: rotate(180deg);
}

.dot-student-homepage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 7px;
  height: 7px;
  border-radius: 100px;
  background: white;
  border: 2px solid #1b1b1b;
  border-radius: 100px;
  margin: auto;
}

.mark-time-6-student-homepage {
  position: absolute;
  width: 13px;
  height: 0px;
  left: 50%;
  top: 96%;
  display: block;
  background-color: #8a8a8a;
  border: 1px solid #8a8a8a;
  transform: translate(-50%, -50%) rotate(90deg);
}

.mark-time-12-student-homepage {
  position: absolute;
  width: 13px;
  height: 0px;
  left: 50%;
  top: 4%;
  display: block;
  background-color: #8a8a8a;
  border: 1px solid #8a8a8a;
  transform: translate(-50%, -50%) rotate(90deg);
}

.mark-time-3-student-homepage {
  position: absolute;
  width: 14px;
  height: 0.5px;
  left: 93%;
  display: block;
  background-color: #8a8a8a;
  top: 50%;
  border: 1px solid #8a8a8a;
}

.mark-time-9-student-homepage {
  position: absolute;
  width: 14px;
  height: 0.5px;
  left: 1.5%;
  display: block;
  top: 50%;
  background-color: #8a8a8a;
  border: 1px solid #8a8a8a;
}

.time-student-homepage {
  font-weight: 500;
  font-size: 45px;
  line-height: 50px;
  /* identical to box height */
  color: #000000;
  margin-bottom: 8px;
  margin-top: 24px;
  margin-left: 10px;
}

.time-student-homepage sup {
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  /* identical to box height */
  color: #000000;
}

.date-student-homepage {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height */
  margin-bottom: 8px;
  color: #000000;
  margin-left: 20px;
}

.subdata {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
}

.dateandtime {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightDiv {
  padding: 17px 18px 17px 18px;
  width: 23%;
  height: 67vh;
  background: linear-gradient(90deg, rgba(51, 215, 228, 0.2) 0%, rgba(40, 169, 230, 0.2) 100%),
    #ffffff;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: inherit;
}

.containDiv {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding: 12px 11px 12px 22px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    #ffffff;
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 16px;
}

.divider {
  border: 1px solid #dcdcdc;
  margin-top: 12px;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #454545;
  margin-top: 8px;
}

.admintype {
  font-style: normal;
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #454545;
}

.discp {
  font-weight: 400;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #8b8b8b;
  margin-top: 8px;
}

.datetime {
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #454545;
  margin-left: 8px;
}

.batchName {
  font-weight: 500;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: #6bbfd8;
  backdrop-filter: blur(7px);
  border-radius: 4px;
  width: fit-content;
  padding: 2px 8px 2px 8px;
}

.attachimgdiv {
  padding: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%),
    rgba(255, 255, 255, 0.3);
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 4px;
}

.containMaindiv {
  height: 42vh;
  overflow-y: scroll;
  padding-right: 8px;
  margin-right: -16px;
}

.containMaindiv::-webkit-scrollbar {
  border-radius: 8px;
  background: rgba(196, 196, 196, 0.4);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  width: 8px;
}

.containMaindiv::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    rgba(0, 151, 249, 0.4) -5.17%,
    rgba(0, 106, 175, 0.4) 106.03%
  );
  border-radius: 8px;
}

.buttombutton {
  background: linear-gradient(180deg, #228fe5 0%, #34d8e5 100%), #ffffff;
  border-radius: 0px 0px 16px 16px;
  margin: 24px -18px -17px -18px;
  padding-top: 6px;
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttombutton button {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #ffffff;
  background: none;
  border: none;
}

/* Media Quries Responsive Design */
@media (max-width: 850px) {
  .outter-cover-student-homepage,
  .inner-cover-student-homepage {
    height: auto;
    min-height: calc(100vh);
  }

  .inner-cover-student-homepage {
    width: 100%;
    align-items: center;
    padding-left: 0px;
  }

  .time-student-homepage,
  .date-student-homepage {
    margin-left: 0px;
  }

  .inner-cover-student-homepage .qoutes-student-homepage {
    margin: auto 16px;
  }

  .outter-cover-student-homepage {
    background: #fff;
  }

  .dashboard_container {
    margin-top: 40px;
    padding: 10px;
  }
}

@media (max-width: 500px) {
  .qoutes-student-homepage {
    margin: 0;
  }
}
