.forgot-div {
    /* border:1px solid black; */
    height: 230px;
    width: 450px;
    background-color: white;
    border-radius: 5px;


    font-family: "Lato";
    font-style: bold;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.01em;

    color: #454545;
    padding: 10px;
}

.fpass-div {
    /* border:1px solid blue; */
    padding: 6px;
    color: blue;
    border-bottom: 1px solid #E5E5E5;
}

.number-phone {
    font-family: "Lato";
    font-style: bold;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.01em;

    color: #454545;


}

.paara-div {
    font-family: "Lato";
    font-style: bold;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    /* or 125% */

    letter-spacing: 0.01em;

    color: #454545;
    padding: 10px;

}

.mobile-No {

    margin-top: 5px;

    display: grid;
    row-gap: 5px;
    padding: 10px;
}


.place {
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 11px;
    /* identical to box height, or 85% */
    background: none;
    padding: 5px;
    border: 1px solid #E5E5E5;
    background: rgba(255, 255, 255, 0.25);
    /* box-shadow: 0px 4px 4px rgba(79, 161, 233, 0.26); */
}

.forgot-buttons {
    display: flex;
    float: right;
    margin-right: 10px;
    /* border:1px solid magenta; */
    margin-top: 15px;
}


.closebutton {
    margin-right: 5px;
    border: 0;
    outline: 0;
    background: blue;
    padding: 8px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    /* identical to box height, or 77% */
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;

}

.closebutton2 {

    margin-right: 5px;
    border: 0;
    outline: 0;
    background: crimson;
    padding: 8px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    /* identical to box height, or 77% */

    letter-spacing: 0.01em;
    text-transform: capitalize;

    color: #FFFFFF;
    cursor: pointer;
    border-radius: 5px;



}