.preview-popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 4;
}

.preview-box {
    position: relative;
    width: 60%;
    margin: 0 auto;
    height: auto;
    max-height: 80vh;
    margin-top: 5vh;
    /* margin-top: calc(100vh - 85vh - 20px); */
    border: 1px solid #999;
    overflow: auto;
    background: #FFFFFF;
    border: 1px solid #DEE2E6;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    overflow: hidden;
   
}

.closeButtondiv {
    background: #0097F9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    width: 68px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 12px;
}

.closebuttonTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.01em;
    color: #FFFFFF
}

.passageTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.01em;

    color: #1A2334;
}