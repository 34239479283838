.event-popup-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: #00000050;
  justify-content: center;
  align-items: center;
  display: flex;
}

.morePopup-box {
  max-height: 70vh;
  width: 35%;
  font-family: Lato;
  font-size: 16px;
  background: white;
  overflow-y: auto;
  position: fixed;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 10;
  top: 5%;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.eventData-box {
  max-height: 50vh;
  width: 25%;
  font-family: Lato;
  font-size: 16px;
  background: white;
  overflow-y: auto;
  position: fixed;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 10;
  top: 25%;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
}

.eventData-cointainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px;
  margin: 0px;
  overflow-y: auto;
  overflow-x: hidden;
}

.eventData_list {
  height: 65%;
}

.eventData-box::-webkit-scrollbar {
  border-radius: 8px;
  background: rgba(196, 196, 196, 0.4);
  box-shadow: inset 0px 2px 4px rgba(0, 0, 0, 0.08);
  width: 8px;
  height: 8px;
}

.eventData-box::-webkit-scrollbar-thumb {
  background:rgba(196, 196, 196, 0.4);
  border-radius: 8px;
}